<template>
  <div>
    <div class="filter-panel">
      <div style="position: relative; display: inline-block">
        <CSSelect i-width="30px" height="30px">
          <input
            @click.stop
            style="padding: 0 10px; width: 240px; border: none; outline: none"
            type="text"
            placeholder="请选择园区"
            @focus="filterRegionPickerVisible = true"
            :value="regionName"
          />
        </CSSelect>

        <TreePanelK @loadMore="loadRegionList" v-if="filterRegionPickerVisible">
          <CSTreeK
            :tree="regionList"
            :showCheckbox="false"
            :enableClickPick="true"
            @change="pickerFilterRegion"
            textName="name"
          />
        </TreePanelK>
        <!-- <CSSelect style="margin-right: 30px">
          <select
            class="form-control typeinput"
            @change="pickerFilterRegion"
          >
            <option value="">请选择园区</option>
            <option
              v-for="(item, n) in regionList"
              :key="`${item.name}_${n}`"
              :value="item"
            >
              {{ item.name }}
            </option>
          </select>
        </CSSelect> -->
      </div>
      <button class="btn btn-primary" @click="queryMenus()">查询</button>

      <button class="btn btn-primary pull-right" @click="openChangeMenu()">
        <svg aria-hidden="true" class="icon">
          <use xlink:href="#icon-menujiahao"></use>
        </svg>
        添加功能
      </button>
    </div>

    <div style="margin-top: 20px">
      <CSTabBar
        :tabbar="tabBarOptions"
        :checkedTab="checkedTab"
        @changeTabBar="changeTabBar"
        style="z-index: 1"
      />
    </div>

    <template v-if="checkedTab === 1">
      <div style="margin-top: 20px">
        <div class="menus-panel">
          <div class="menus-panel-header">棒棒我鸭小程序首页功能区域第一页</div>
          <div class="menus-group" @dragover="(e) => e.preventDefault()">
            <div
              class="menu-item"
              v-for="(menu, index) in menus[0]"
              @drop="menuDrop($event, index)"
              @dragover="(e) => e.preventDefault()"
              :key="menu.iconUrl"
            >
              <div
                @dragstart="menuStartDrop($event, menu, index)"
                draggable="true"
              >
                <div>
                  <img :src="menu.iconUrl" alt="" />
                  <span>{{ menu.menuName }}</span>
                </div>
                <div>
                  <button class="btn btn-primary" @click="openChangeMenu(menu)">
                    修改
                  </button>
                  <button class="btn btn-primary" @click="deleteMenu(menu.id)">
                    删除
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menus-panel">
          <div class="menus-panel-header">棒棒我鸭小程序首页功能区域第二页</div>
          <div class="menus-group" @dragover="(e) => e.preventDefault()">
            <div
              class="menu-item"
              v-for="(menu, index) in menus[1]"
              @drop="menuDrop($event, 8 + index)"
              @dragover="(e) => e.preventDefault()"
              :key="menu.menuName"
            >
              <div
                @dragstart="menuStartDrop($event, menu, 8 + index)"
                draggable="true"
              >
                <div>
                  <img :src="menu.iconUrl" alt="" />
                  <span>{{ menu.menuName }}</span>
                </div>
                <div>
                  <button class="btn btn-primary" @click="openChangeMenu(menu)">
                    修改
                  </button>
                  <button class="btn btn-primary" @click="deleteMenu(menu.id)">
                    删除
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tip">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-menua-zu92"></use>
        </svg>
        <div
          style="display: inline-block; vertical-align: top; margin-left: 5px"
        >
          <p>1、长按图标可拖动调整功能位置。</p>
          <p>2、修改后，请记得保存，否则棒棒我鸭小程序首页功能区域不会更新。</p>
        </div>
      </div>
      <button class="btn btn-primary" @click="saveTips">保存</button>

      <CSDialog
        :dialog-visible="dialog.visible"
        dialog-width="540px"
        :dialog-title="dialog.title"
        @onConfirm="dialog.onConfirm"
        @onClose="dialog.onClose"
        :is-submitting="isSubmitting"
      >
        <template v-slot:dialog-content>
          <div class="menu-dialog-content">
            <div v-if="dialog.title === '添加'" class="item">
              <div class="item-label">园区</div>
              <div>
                <CSSelect i-width="40px" height="40px">
                  <input
                    @click.stop
                    style="
                      padding: 0 10px;
                      width: 240px;
                      border: none;
                      outline: none;
                    "
                    type="text"
                    placeholder="请选择园区"
                    @focus="regionPickerVisible = true"
                    :value="
                      regionCodes.length > 0 ? `${regionCodes.length}个` : ''
                    "
                  />
                </CSSelect>
                <TreePanel
                  @loadMore="loadRegionList"
                  v-if="regionPickerVisible"
                >
                  <CSTree
                    :tree="regionList"
                    @change="pickerRegion"
                    textName="name"
                  />
                </TreePanel>
              </div>
            </div>
            <div class="item">
              <div class="item-label">图标</div>
              <div>
                <img
                  v-if="iconUrl"
                  style="
                    width: 80px;
                    height: 80px;
                    border-radius: 4px;
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    margin-right: 10px;
                    margin-top: 0 !important;
                  "
                  @click="triggerChoosePhoto"
                  :src="iconUrl"
                />
                <div v-else class="upload-photo" @click="triggerChoosePhoto">
                  <div class="text-center" style="top: 35px">
                    <img
                      src="../../assets/upload.png"
                      alt=""
                      style="margin: 5px auto"
                    />
                    <p style="color: #999">上传图标</p>
                  </div>
                </div>
                <input
                  type="file"
                  class="file"
                  accept="images/*"
                  id="uploadPhoto"
                  hidden
                  @change="chooseStaffPhoto($event)"
                />
              </div>
            </div>
            <div class="item">
              <div class="item-label">名称</div>
              <div style="padding: 3px">
                <input
                  v-model="menuName"
                  type="text"
                  placeholder="限2-4字"
                  maxlength="4"
                />
              </div>
            </div>
            <div class="item" style="padding-bottom: 1px">
              <div class="item-label">跳转路径</div>
              <div>
                <input v-model="menuUrl" type="text" placeholder="请输入" />
              </div>
            </div>
          </div>
        </template>
      </CSDialog>
      <CSDialog
        dialogTitle="提示"
        dialog-header-class=" "
        dialogWidth="480px"
        dialog-confirm-btn-text="确定"
        :dialogVisible="saveVisible"
        @onClose="saveVisible = false"
        @onConfirm="saveOrder"
        dialog-header-icon="icon-menua-zu13"
      >
        <div
          slot="dialog-content"
          style="padding: 20px 30px; text-align: center; font-size: 24px"
        >
          确定保存吗?
        </div>
      </CSDialog>
    </template>
    <template v-if="checkedTab === 2">
      <ProjectionMenu :regionCode="this.regionCode"></ProjectionMenu>
    </template>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import { STORAGE_KEY } from "@/constant";
import CSDialog from "@/components/common/CSDialog";
import CSTabBar from "@/components/common/CSTabBar";
import ProjectionMenu from "./ProjectionMenu";
import {
  addMiniProgramMenuUrl,
  commonImgOssDomain,
  deleteMiniProgramUrlUrl,
  modifyMiniProgramUrl,
  queryMiniProgramUrl,
  queryRegionListUrl,
  uploadFileUrl,
} from "@/requestUrl";
import TreePanelK from "./TreePanelK.vue";
import TreePanel from "@/components/common/TreePanel.vue";
import CSTree from "@/components/common/CSTree";
import CSTreeK from "./CSTreeK.vue";
export default {
  name: "MiniProgramMenu",
  components: {
    CSTree,
    TreePanel,
    TreePanelK,
    CSTreeK,
    CSDialog,
    CSSelect,
    CSTabBar,
    ProjectionMenu,
  },
  mounted() {
    console.log('触发mounted');


  },
  data() {
    return {
      tabBarOptions: {
        1: "菜单",
        2: "功能",
      },
      checkedTab: 1,
      isSubmitting: false,
      iconUrl: "",
      regionCodes: [],
      regionCode: this.$vc.getCurrentRegion().code,
      regionName: this.$vc.getCurrentRegion().name,
      menuUrl: "",
      menuName: "",
      filterRegionPickerVisible: false,
      regionPickerVisible: false,
      dialog: {
        visible: false,
        title: "添加",
        onConfirm: () => {},
        onClose: () => (this.dialog.visible = false),
      },
      menus: [[], []],
      regionList: [],
      filterRegionList: [],
      saveVisible: false,
    };
  },
  async created() {
    this.loadRegionList = this.getRegionList(1);
    await this.loadRegionList(1, this.regionCodes);
    this.queryMenus();
    document.body.addEventListener("click", (event) => {
      this.regionPickerVisible = false;
      this.filterRegionPickerVisible = false;
    });

  },
  methods: {
    changeTabBar(index) {
      this.checkedTab = index;
      this.$nextTick(() => {
        this.$vc.emit("setup", this.regionCode);
      })
    },
    menuStartDrop(e, menu, index) {
      e.dataTransfer.setData(
        "data",
        JSON.stringify({
          index,
          menu,
        })
      );
    },
    menuDrop(e, index) {
      const menus = [...this.menus[0], ...this.menus[1]];
      const menu = JSON.parse(e.dataTransfer.getData("data"));
      if (index === menu.index) {
        return;
      }
      menus.splice(menu.index, 1);
      if (menu.index < index) {
        menus.splice(index, 0, menu.menu);
      }
      if (menu.index > index) {
        menus.splice(index, 0, menu.menu);
      }
      this.$set(this.menus, 0, menus.slice(0, 8));
      this.$set(this.menus, 1, menus.slice(8, menus.length));
      e.dataTransfer.clearData("data");
    },
    /**
     * 选中筛选的管理区
     * @param {Object} {key， source} 选中的管理区
     *  - key: 选中元素的下表
     *  - source: 原本的数据
     * */
    pickerFilterRegion({ key, source }) {
      this.regionCode = source[key].code;
      this.regionName = source[key].name;
      this.filterRegionPickerVisible = false;
      this.$vc.emit("setup", this.regionCode);
    },
    /**
     * 选中的管理区
     * @param {Object} {key， source} 选中的管理区
     *  - key: 选中元素的下表
     *  - source: 原本的数据
     * */
    pickerRegion({ key, source }) {
      const code = source[key].code,
        firstIndex = this.regionCodes.indexOf(code);
      if (firstIndex >= 0) {
        this.regionCodes.splice(firstIndex, 1);
        source[key].checked = false;
        return;
      }
      source[key].checked = true;
      this.regionCodes.push(code);
    },
    /**
     * 打开修改菜单
     * @param {Object} menuInfo 菜单信息
     * */
    async openChangeMenu(menuInfo) {
      if (menuInfo) {
        this.dialog.title = "修改";
        this.iconUrl = menuInfo.iconUrl;
        this.menuUrl = menuInfo.menuUrl;
        this.menuName = menuInfo.menuName;
      } else {
        this.dialog.title = "添加";
        this.iconUrl = "";
        this.menuUrl = "";
        this.menuName = "";
      }
      this.regionCodes = [];
      await this.loadRegionList(1, []);
      this.dialog.visible = true;
      this.dialog.onConfirm = async () => {
        let res;
        this.isSubmitting = true;
        if (menuInfo) {
          res = await this.editMenu(menuInfo.id, menuInfo.orderNo);
        } else {
          res = await this.addMenu();
        }
        this.isSubmitting = false;
        if (res?.code === 0) {
          this.dialog.visible = false;
          this.queryMenus();
        }
      };
      this.$nextTick(() => {

        document
          .querySelector(".menu-dialog-content")
          .addEventListener("click", () => {
            this.regionPickerVisible = false;
          });
      });
    },
    /**
     * 删除菜单
     * @param {Number} id 菜单id
     * */
    deleteMenu(id) {
      this.$CSDialog.confirm({
        title: "提示",
        message: "确定删除菜单吗？",
        onConfirm: () => {
          this.$fly
            .get(deleteMiniProgramUrlUrl, {
              regionCode: this.regionCode,
              id,
            })
            .then((res) => {
              if (res.code !== 0) return;
              this.$CSDialog.instance.closeDialog();
              this.queryMenus();
            });
        },
      });
    },
    // 查询菜单列表
    queryMenus() {
      this.$fly
        .get(queryMiniProgramUrl, {
          regionCode: this.regionCode,
        })
        .then((res) => {
          if (res.code !== 0) return;
          this.menus = [[], []];
          const sortData = res.data.sort((next, current) => {
            return next.orderNo - current.orderNo;
          });
          this.menus[0] = sortData.slice(0, 8);
          if (sortData.length > 8) {
            this.menus[1] = sortData.slice(8, sortData.length);
          }
        });
    },
    /**
     * 修改菜单
     * @param {Number} id 菜单id
     * @param {Number} orderNo 菜单顺序
     * @param {Object} menuInfo 菜单数据
     * */
    editMenu(id, orderNo, menuInfo) {
      const { iconUrl, menuUrl, menuName, regionCode } = menuInfo || this;
      return this.$fly
        .post(modifyMiniProgramUrl, {
          id,
          iconUrl,
          menuUrl,
          menuName,
          regionCode: [regionCode],
          orderNo,
        })
        .then((res) => res);
    },
    // 添加菜单
    addMenu() {
      const { iconUrl, menuUrl, menuName, regionCodes } = this;
      return this.$fly
        .post(addMiniProgramMenuUrl, {
          iconUrl,
          menuUrl,
          menuName,
          regionCode: regionCodes,
        })
        .then((res) => res);
    },
    // 获取管理区
    getRegionList(pageNo) {
      let _page = pageNo;
      const pageSize = 10;
      return (pageNo, checkedRegion) => {
        if (
          this.regionList.length > 0 &&
          this.regionList.length % pageSize != 0 &&
          !pageNo
        ) {
          return;
        }
        if (!pageNo) {
          _page++;
        } else {
          _page = pageNo;
        }
        return this.$fly
          .post(queryRegionListUrl, {
            code: "",
            name: "",
            pageNo: _page,
            pageSize,
          })
          .then((res) => {
            let regionList = this.dialog.visible
              ? this.regionList
              : this.filterRegionList;
            if (_page === 1) {
              regionList = res.data.datas.map((val) => {
                val.checked = checkedRegion.includes(val.code);
                return val;
              });
            } else {
              regionList.push(
                ...res.data.datas.map((val) => {
                  val.checked = checkedRegion.includes(val.code);
                  return val;
                })
              );
            }
            if (this.dialog.visible) {
              this.filterRegionList = regionList;
            } else {
              this.regionList = regionList;
            }
            return res.data.datas;
          });
      };
    },
    //  调起选择文件
    triggerChoosePhoto() {
      $("#uploadPhoto").trigger("click");
    },
    // 选择文件后处理
    chooseStaffPhoto(event) {
      const photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        const file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 5) {
          this.$vc.toast("图片大小不能超过 5MB!");
          return false;
        }
        const formData = new FormData();
        formData.append("file", file);
        this.$fly.upload(uploadFileUrl, formData).then((res) => {
          event.target.value = "";
          this.iconUrl = commonImgOssDomain + res.data.ossUrl;
        });
      }
    },
    saveTips() {
      this.saveVisible = true;
    },
    saveOrder() {
      Promise.all([
        ...this.menus[0].map((val, index) => {
          return this.editMenu(val.id, index + 1, val);
        }),
        ...this.menus[1].map((val, index) => {
          return this.editMenu(val.id, index + 8 + 1, val);
        }),
      ]).then((res) => {
        if (res.every((val) => val.code === 0)) {
          this.$vc.toast("保存成功");
          this.saveVisible = false;
        }
      });
    },
    // 油猴脚本
    monkeyScript() {
      const CHECKED_CLASS = "hy-checked" + location.hostname;
      // 抓取的第几步
      let step;
      // 存储选中的node节点
      let checkedNode = new Map(),
        checkedNextPageNode = new Map();
      // 存储原有dom的事件
      let eventMap = new Map();
      //  是否允许选择页面中节点
      let allowCheck = false;
      eventMap.set(document.body, document.body.onclick);

      // 提交当前选中的节点结果
      function commitCheckedNode() {
        GM_xmlhttpRequest({
          method: "",
          url: "",
          headers: {},
          cookie: "",
          data: {},
          responseType: "json",
          onload: function (response) {
            console.log(response, "返回结果");
          },
        });
        step = null;
      }

      /**
       * 选中dom后的处理
       * @param {HTMLEvent} 元素的事件对象
       * @param {Map} nodeMap map对象
       * */
      function checkedDom(e, nodeMap) {
        console.log(e, "选中的元素");
        eventMap.set(e.target, e.target.onclick);
        e.target.onclick = () => false;
        if (e.target.classList.contains(CHECKED_CLASS)) {
          nodeMap.delete(e.target);
          e.target.classList.remove(CHECKED_CLASS);
          e.target.style = "background: unset";
        } else {
          try {
            const { className, parentNode, children, localName, id, href } =
              e.target;
            const classList = className.replace(CHECKED_CLASS, "").split(" ");
            const selfClass =
                classList && classList.length > 1
                  ? "." + classList.join(".")
                  : localName,
              parent = getParentElNames(parentNode);
            nodeMap.set(e.target, {
              selfClass,
              selfClassList: classList,
              parent,
              elName: localName,
              id,
              href,
              checkedPath: parent + " > " + selfClass,
              children: Object.values(children).map((child) => {
                if (!child.className || child.className === "") {
                  return child.localName;
                }
                return child.className.replace(CHECKED_CLASS, "");
              }),
            });
            console.log(nodeMap, "已经选中的node节点");
          } catch (err) {
            console.log("选中节点错误", err);
          }
          e.target.style = "background: yellow";
          e.target.classList.add(CHECKED_CLASS);
        }
        e.stopImmediatePropagation();
        return false;
      }

      // 选中抓取内容dom
      function checkedContentNode(e) {
        checkedDom(e, checkedNode);
      }
      // 选中翻页的dom
      function checkedPageNode(e) {
        checkedDom(e, checkedNextPageNode);
      }

      /**
       * 获取父级元素的名称（选择器）
       * @param {HTMLNode} parent 父元素节点
       * */
      function getParentElNames(parent) {
        const { className, id, localName, parentNode } = parent;
        let currentElName, nthChildIndex;
        if (parentNode.childElementCount > 1) {
          for (let i = 0; i < parentNode.childElementCount; i++) {
            if (parentNode.childNodes[i] === parent) {
              console.log(parentNode.childNodes[i], "对比是否一样" + i, parent);
              nthChildIndex = i;
            }
          }
        }
        if (id) {
          currentElName = id;
        } else {
          currentElName =
            localName +
            (className && className.length > 0
              ? "." + className.replace(CHECKED_CLASS, "").split(" ").join(".")
              : "") +
            (nthChildIndex && (!className || className.length === 0)
              ? `:nth-child(${nthChildIndex + 1})`
              : "");
        }
        if (parentNode && localName !== "body") {
          return getParentElNames(parentNode) + " > " + currentElName;
        }
        return currentElName;
      }

      // 弹出输入提示
      function inputStepDialog() {
        step = prompt("输入当前所属步骤(只能输入整数)", "");
        if (step === "" || isNaN(+step)) {
          alert("输入值不规范请重试");
          inputStepDialog();
        }
      }

      window.addEventListener(
        "keydown",
        function (event) {
          if (event.defaultPrevented) {
            return; // 如果已取消默认操作，则不应执行任何操作
          }
          console.log(event.key);
          if (event.altKey && event.key === "1") {
            if (allowCheck) {
              document.body.removeEventListener("click", checkedContentNode);
              document.body.removeEventListener("contextmenu", checkedPageNode);
              eventMap.forEach((val, key) => {
                key.onclick = function () {
                  if (typeof val === "function") {
                    val();
                  }
                };
              });
              alert("节点选择关闭");
              commitCheckedNode();
            } else {
              inputStepDialog();
              document.body.onclick = () => false;
              document.body.addEventListener("contextmenu", checkedPageNode);
              document.body.addEventListener("click", checkedContentNode);
            }
            allowCheck = !allowCheck;
          }
        },
        true
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
.btn-primary {
  line-height: 1.15;
  padding: 6px 10px;
}

.menus-panel {
  width: 590px;
  height: 235px;
  background: #FFFFFF;
  padding: 15px 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  display: inline-block;
  vertical-align: top;
  color: #000;

  &:not(:last-of-type) {
    margin-right: 40px;
  }

  &-header {
    font-size: 14px;
    line-height: 1;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .menus-group {
    display: flex;
    flex-flow: row wrap;
    height: 180px;
    align-items: space-between;

    // justify-content space-between
    .menu-item {
      width: 24%;
      font-size: 14px;

      &:not(:nth-of-type(4n)) {
        margin-right: calc((4% / 3));
      }

      & > div {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        text-align: center;

        & > div {
          flex: auto;

          img {
            display: block;
            height: 60px;
            width: 60px;
            margin: 0 auto;
          }

          span {
            display: block;
            text-align: center;
            margin: 0 auto;
          }
        }

        button {
          display: block;
          min-width: 50px;

          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.tip {
  margin-top: 20px;
  color: #999;
  padding-left: 20px;
  margin-bottom: 30px;
  position: relative;

  .icon {
    margin-left: -20px;
  }

  p {
    margin-bottom: 0;
  }
}

.menu-dialog-content {
  padding: 30px 50px;
  margin: 0 auto;

  .item {
    display: flex;
    align-items: center;
    font-size: 0;

    &-label {
      margin-right: 40px;
      width: 120px;
    }

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    & > div {
      font-size: 24px;
      display: inline-block;
      position: relative;
    }
  }
}
</style>
